import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

interface FileListProps {
  path: string;
}

interface FileItem {
  name: string;
  path: string;
  type: string;
  sha: string;
  content?: string;
  pendingDelete?: boolean;
}

interface NewFileForm {
  name: string;
  title: string;
  description: string;
  date: string;
}

const OWNER = 'IonGlue';
const REPO = 'the-fellows-nl-v2';
const API_BASE_URL = 'https://api.github.com';
const GITHUB_TOKEN = import.meta.env.VITE_GITHUB_TOKEN;

export default function FileList({ path }: FileListProps) {
  const [files, setFiles] = useState<FileItem[]>([]);
  const [pendingDeletes, setPendingDeletes] = useState<Set<string>>(new Set());
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showNewFileModal, setShowNewFileModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState<string | null>(null);
  const [refreshKey, setRefreshKey] = useState(0);
  const [newFile, setNewFile] = useState<NewFileForm>({
    name: '',
    title: '',
    description: '',
    date: new Date().toISOString().split('T')[0]
  });
  const navigate = useNavigate();

  const fetchFiles = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_BASE_URL}/repos/${OWNER}/${REPO}/contents/${path}`, {
        headers: {
          'Accept': 'application/vnd.github.v3+json',
          'Authorization': `Bearer ${GITHUB_TOKEN}`
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (Array.isArray(data)) {
        const mdFiles = data.filter(file => file.type === 'file' && file.name.endsWith('.md'));
        
        const filesWithContent = await Promise.all(
          mdFiles.map(async (file) => {
            const contentResponse = await fetch(`${API_BASE_URL}/repos/${OWNER}/${REPO}/contents/${file.path}`, {
              headers: {
                'Accept': 'application/vnd.github.v3+json',
                'Authorization': `Bearer ${GITHUB_TOKEN}`
              }
            });
            const contentData = await contentResponse.json();
            const content = atob(contentData.content);
            return { ...file, content };
          })
        );
        
        setFiles(filesWithContent);
      }
      setLoading(false);
    } catch (err) {
      const error = err as Error;
      setError(error.message || 'Failed to fetch files');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, [path, refreshKey]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setNewFile(prev => ({
      ...prev,
      [name]: value,
      title: name === 'name' ? value.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase()) : prev.title
    }));
  };

  const createNewFile = async () => {
    if (!newFile.name) return;

    const fileName = newFile.name.endsWith('.md') ? newFile.name : `${newFile.name}.md`;
    const filePath = `${path}/${fileName}`;
    
    const initialContent = `---
title: ${newFile.title}
description: ${newFile.description}
date: ${newFile.date}
video:
  src: ""
  poster: ""
---

# ${newFile.title}

${newFile.description}
`;

    try {
      const response = await fetch(`${API_BASE_URL}/repos/${OWNER}/${REPO}/contents/${filePath}`, {
        method: 'PUT',
        headers: {
          'Accept': 'application/vnd.github.v3+json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${GITHUB_TOKEN}`
        },
        body: JSON.stringify({
          message: `Create ${fileName}`,
          content: btoa(initialContent),
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to create file: ${response.statusText}`);
      }

      await response.json();
      
      setShowNewFileModal(false);
      setNewFile({
        name: '',
        title: '',
        description: '',
        date: new Date().toISOString().split('T')[0]
      });
      setRefreshKey(prev => prev + 1);
      navigate(`/edit/${filePath}`);
    } catch (err) {
      const error = err as Error;
      setError(error.message || 'Failed to create file');
    }
  };

  const markForDeletion = (filePath: string) => {
    setPendingDeletes(prev => new Set([...prev, filePath]));
    setShowDeleteModal(null);
  };

  const unmarkForDeletion = (filePath: string) => {
    setPendingDeletes(prev => {
      const newSet = new Set(prev);
      newSet.delete(filePath);
      return newSet;
    });
  };

  const submitChanges = async () => {
    if (pendingDeletes.size === 0) return;

    try {
      setLoading(true);
      const deletePromises = Array.from(pendingDeletes).map(async (filePath) => {
        const file = files.find(f => f.path === filePath);
        if (!file) return;

        const response = await fetch(`${API_BASE_URL}/repos/${OWNER}/${REPO}/contents/${file.path}`, {
          method: 'DELETE',
          headers: {
            'Accept': 'application/vnd.github.v3+json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${GITHUB_TOKEN}`
          },
          body: JSON.stringify({
            message: `Delete ${file.name}`,
            sha: file.sha,
          }),
        });

        if (!response.ok) {
          throw new Error(`Failed to delete ${file.name}`);
        }
      });

      await Promise.all(deletePromises);
      setPendingDeletes(new Set());
      setRefreshKey(prev => prev + 1);
    } catch (err) {
      const error = err as Error;
      setError(error.message || 'Failed to submit changes');
    } finally {
      setLoading(false);
    }
  };

  const extractDateFromContent = (content: string): string => {
    const dateMatch = content.match(/date:\s*["']?(\d{4}-\d{2}-\d{2})["']?/);
    if (dateMatch && dateMatch[1]) {
      const date = new Date(dateMatch[1]);
      return date.toLocaleDateString('en-US', { 
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    }
    return '';
  };

  const formatTitle = (filename: string): string => {
    return filename
      .replace('.md', '')
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="error">{error}</div>;

  const hasChanges = pendingDeletes.size > 0;

  return (
    <div className="case-studies">
      <div className="case-studies-header">
        <h1 className="case-studies-title">Case Studies</h1>
        <div className="header-buttons">
          {hasChanges && (
            <button 
              className="submit-changes-button"
              onClick={submitChanges}
              disabled={loading}
            >
              Submit Changes
            </button>
          )}
          <button 
            className="new-file-button"
            onClick={() => setShowNewFileModal(true)}
          >
            New Case Study
          </button>
        </div>
      </div>

      {showNewFileModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Create New Case Study</h2>
            <div className="modal-form">
              <div className="form-group">
                <label htmlFor="name">File Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={newFile.name}
                  onChange={handleInputChange}
                  placeholder="e.g., my-case-study"
                  className="modal-input"
                />
              </div>
              <div className="form-group">
                <label htmlFor="description">Description:</label>
                <textarea
                  id="description"
                  name="description"
                  value={newFile.description}
                  onChange={handleInputChange}
                  placeholder="Enter case study description"
                  className="modal-input"
                  rows={3}
                />
              </div>
              <div className="form-group">
                <label htmlFor="date">Date:</label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  value={newFile.date}
                  onChange={handleInputChange}
                  className="modal-input"
                />
              </div>
            </div>
            <div className="modal-buttons">
              <button onClick={() => setShowNewFileModal(false)}>Cancel</button>
              <button onClick={createNewFile}>Create</button>
            </div>
          </div>
        </div>
      )}

      {showDeleteModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Delete Case Study</h2>
            <p>Are you sure you want to mark this case study for deletion? You can undo this action before submitting changes.</p>
            <div className="modal-buttons">
              <button onClick={() => setShowDeleteModal(null)}>Cancel</button>
              <button 
                onClick={() => markForDeletion(showDeleteModal)} 
                className="delete-button"
              >
                Mark for Deletion
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="case-studies-grid">
        {files.map((file) => {
          const date = file.content ? extractDateFromContent(file.content) : '';
          const title = formatTitle(file.name);
          const isPendingDelete = pendingDeletes.has(file.path);
          
          return (
            <div key={file.sha} className={`case-study-card ${isPendingDelete ? 'pending-delete' : ''}`}>
              <Link to={`/edit/${file.path}`} className="case-study-content">
                <h2>{title}</h2>
                {date && <p className="case-study-date">{date}</p>}
              </Link>
              {isPendingDelete ? (
                <button 
                  className="undo-delete-icon"
                  onClick={() => unmarkForDeletion(file.path)}
                  title="Undo mark for deletion"
                >
                  <svg viewBox="0 0 24 24" width="16" height="16">
                    <path 
                      stroke="currentColor" 
                      strokeWidth="2" 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      fill="none" 
                      d="M3 10h10a3 3 0 0 1 3 3v6M3 10l6-6m-6 6l6 6"
                    />
                  </svg>
                </button>
              ) : (
                <button 
                  className="delete-icon"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowDeleteModal(file.path);
                  }}
                  title="Mark for deletion"
                >
                  <svg viewBox="0 0 24 24" width="16" height="16">
                    <path 
                      stroke="currentColor" 
                      strokeWidth="2" 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      fill="none" 
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    />
                  </svg>
                </button>
              )}
            </div>
          );
        })}
      </div>

      <style>{`
        .case-studies {
          padding: 20px;
          max-width: 1200px;
          margin: 0 auto;
        }

        .case-studies-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 30px;
        }

        .header-buttons {
          display: flex;
          gap: 12px;
          align-items: center;
        }

        .case-studies-title {
          font-size: 2rem;
          color: #e0e0e0;
          margin: 0;
        }

        .new-file-button {
          padding: 10px 20px;
          background: #2ea043;
          color: white;
          border: none;
          border-radius: 6px;
          cursor: pointer;
          font-weight: 500;
          transition: all 0.2s ease;
        }

        .new-file-button:hover {
          background: #3fb950;
          transform: translateY(-1px);
        }

        .submit-changes-button {
          padding: 10px 20px;
          background: #0d6efd;
          color: white;
          border: none;
          border-radius: 6px;
          cursor: pointer;
          font-weight: 500;
          transition: all 0.2s ease;
        }

        .submit-changes-button:hover {
          background: #0b5ed7;
          transform: translateY(-1px);
        }

        .submit-changes-button:disabled {
          opacity: 0.7;
          cursor: not-allowed;
          transform: none;
        }

        .case-studies-grid {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
          gap: 20px;
        }

        .case-study-card {
          background: #2d2d2d;
          border-radius: 8px;
          overflow: hidden;
          transition: all 0.2s ease;
          position: relative;
          display: flex;
        }

        .case-study-card.pending-delete {
          background: #2d2d2d88;
          border: 2px solid #d73a49;
        }

        .case-study-content {
          flex: 1;
          padding: 20px;
          text-decoration: none;
          color: inherit;
        }

        .case-study-card:hover {
          transform: translateY(-2px);
        }

        .case-study-card h2 {
          color: #e0e0e0;
          margin: 0 0 10px 0;
          font-size: 1.25rem;
        }

        .case-study-date {
          color: #a0a0a0;
          margin: 0;
          font-size: 0.9rem;
        }

        .delete-icon,
        .undo-delete-icon {
          background: none;
          border: none;
          padding: 8px;
          color: #a0a0a0;
          cursor: pointer;
          transition: color 0.2s ease;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 8px;
        }

        .delete-icon:hover {
          color: #ff6b6b;
        }

        .undo-delete-icon {
          color: #d73a49;
        }

        .undo-delete-icon:hover {
          color: #ff6b6b;
        }

        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.75);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }

        .modal {
          background: #2d2d2d;
          padding: 24px;
          border-radius: 8px;
          width: 100%;
          max-width: 400px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        .modal h2 {
          color: #e0e0e0;
          margin: 0 0 16px 0;
        }

        .modal p {
          color: #d4d4d4;
          margin: 0 0 24px 0;
        }

        .modal-form {
          margin-bottom: 24px;
        }

        .form-group {
          margin-bottom: 16px;
        }

        .form-group label {
          display: block;
          margin-bottom: 8px;
          color: #d4d4d4;
        }

        .modal-input {
          width: 100%;
          padding: 8px 12px;
          background: #1e1e1e;
          border: 1px solid #3d3d3d;
          border-radius: 6px;
          color: #e0e0e0;
          font-family: inherit;
        }

        .modal-input:focus {
          outline: none;
          border-color: #0d6efd;
        }

        .modal-buttons {
          display: flex;
          justify-content: flex-end;
          gap: 12px;
        }

        .modal-buttons button {
          padding: 8px 16px;
          border: none;
          border-radius: 6px;
          font-weight: 500;
          cursor: pointer;
          transition: all 0.2s ease;
        }

        .modal-buttons button:first-child {
          background: #3d3d3d;
          color: #d4d4d4;
        }

        .modal-buttons button:first-child:hover {
          background: #4d4d4d;
        }

        .delete-button {
          background: #d73a49;
          color: white;
        }

        .delete-button:hover {
          background: #cb2431;
        }

        .error {
          padding: 20px;
          background: rgba(255, 107, 107, 0.1);
          color: #ff6b6b;
          border-radius: 8px;
          margin-bottom: 20px;
        }
      `}</style>
    </div>
  );
}
