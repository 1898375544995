import { BrowserRouter as Router, Routes, Route, Navigate, useParams, Link } from 'react-router-dom';
import FileList from './components/FileList';
import MarkdownEditor from './components/MarkdownEditor';
import AccessControl from './components/AccessControl';
import './App.css';

function EditFile() {
  const { '*': path = '' } = useParams();
  return <MarkdownEditor path={path} />;
}

export default function App() {
  return (
    <AccessControl>
      <Router>
        <div className="app">
          <nav className="nav">
            <div className="nav-left">
              <Link to="/" className="logo-link">
                <img 
                  src="https://imagedelivery.net/N5vt_JZzpkwJ3h9_FiD6WA/5b4efb45-1c8c-4ee7-9b7c-aed5b0423d00/public" 
                  alt="The Fellows" 
                  className="logo-img"
                />
              </Link>
              <Link to="/" className="nav-link">Case Studies</Link>
            </div>
            <div className="nav-right">
              <a 
                href="https://the-fellows.nl" 
                target="_blank" 
                rel="noopener noreferrer"
                className="site-link"
              >
                Visit Site
                <img 
                  src="https://imagedelivery.net/N5vt_JZzpkwJ3h9_FiD6WA/54b09b5c-8395-4fbe-4809-d51259392200/public" 
                  alt="Visit Site" 
                  className="external-link-icon" 
                  width="16" 
                  height="16"
                />
              </a>
            </div>
          </nav>
          
          <main className="app-content">
            <Routes>
              <Route path="/" element={<Navigate to="/browse" replace />} />
              <Route path="/browse" element={<FileList path="src/content/cases" />} />
              <Route path="/browse/*" element={<FileList path="src/content/cases" />} />
              <Route path="/edit/*" element={<EditFile />} />
            </Routes>
          </main>

          <footer className="footer">
            <img 
              src="https://imagedelivery.net/N5vt_JZzpkwJ3h9_FiD6WA/5b4efb45-1c8c-4ee7-9b7c-aed5b0423d00/public" 
              alt="The Fellows" 
              className="logo-img"
            />
          </footer>
        </div>
      </Router>
    </AccessControl>
  );
}
