import { ReactNode, useEffect, useState } from 'react';

interface AccessControlProps {
  children: ReactNode;
}

export default function AccessControl({ children }: AccessControlProps) {
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    const hostname = window.location.hostname;
    
    const isLocalhost = hostname === 'localhost' || hostname === '127.0.0.1';
    const isAdminDomain = hostname === 'admin.the-fellows.nl';
    
    setIsAllowed(isLocalhost || isAdminDomain);
  }, []);

  if (!isAllowed) {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        padding: '20px'
      }}>
        <h1 style={{ color: '#ff4444' }}>Access Denied</h1>
        <p>This application can currently not be accessed.</p>
      </div>
    );
  }

  return <>{children}</>;
}
